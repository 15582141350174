/* custom-tree.css */
.node__root > circle {
  fill: rgb(25, 15, 230);
}

.node__branch > circle {
  fill: yellow;
}

.node__leaf > circle {
  fill: green;
  /* Let's also make the radius of leaf nodes larger */
  r: 20;
}